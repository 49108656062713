module controllers {
    export module applicationcore {
        interface IAboutGTSScope extends ng.IScope {
            IsLoading: boolean;
            ShowGrid: boolean;
        }

        export class aboutGTSCtrl {
            static $inject = ['$scope',
                '$rootScope',
                '$q',
                'generalService',
                'localeService',
                'userAccountService',
                'eventTypeService',
                'bsLoadingOverlayService',
                '$state',
                '$timeout',
                'aboutGTSService'
            ];

            showGrid: boolean = true;
            IsLoading: boolean = false;

            apiManualList: uiGrid.IGridApi;
            about: interfaces.applicationcore.IAboutGTS;
            currentDate: moment.Moment = moment().utc();
            
            constructor(
                private $scope: IAboutGTSScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private $q: ng.IQService,
                public generalService: interfaces.applicationcore.IGeneralService,
                public localService: interfaces.applicationcore.ILocaleService,
                public userService: interfaces.applicationcore.IUserAccountService,
                public eventTypeService: interfaces.applicationcore.IEventTypeService,
                public bsLoadingOverlayService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                private aboutGTSService: interfaces.applicationcore.IAboutGTSService,
            ) {
                this.loadAboutDetails();
            }

            loadAboutDetails() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: "about.gts"
                }, () => {
                    return this.aboutGTSService.getDetails().query((result: interfaces.applicationcore.IAboutGTS) => {
                        this.about = result;
                        this.gvwManuals.data = result.Manuals;
                    }, (errorResult) => {
                        this.generalService.displayMessageHandler(errorResult.data);
                        this.gvwManuals.data = [];
                    }).$promise;
                });
            }

            gvwManuals: uiGrid.IGridOptions | any = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                enableSorting: true,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableRowSelection: true,
                enableFullRowSelection: true,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enableVerticalScrollbar: 1,
                rowHeight: 36,
                showGridFooter: true,
                //Export to Excel
                enableGridMenu: true,
                exporterMenuCsv: false,
                exporterMenuPdf: false,
                exporterMenuExcel: true,
                exporterExcelFilename: 'Manuals.xlsx',
                gridMenuShowHideColumns: false,
                exporterFieldCallback: (grid, row, col, value) => {
                    return this.generalService.handleValue(value);
                },
                exporterSuppressColumns: ['PDF'],
                onRegisterApi: (gridApi) => {
                    this.registerGridApi(gridApi);
                },
                columnDefs: [{
                    name: "Description",
                    displayName: "Description",
                    field: "Description",
                    width: 300,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                },{
                        name: "Module",
                        displayName: "Module",
                        field: "Module",
                        width: 200,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                    </div>
                                </div>`,
                    }, {
                        name: "ManualDetail",
                        displayName: "Manual Detail",
                        field: "ManualDetail",
                        width: 350,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                    </div>
                                </div>`,
                    }, {
                        name: "Locale",
                        displayName: "Locale",
                        field: "Locale",
                        width: 200,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                    </div>
                                </div>`,
                    }, {
                        name: "PDF",
                        displayName: "",
                        enableFiltering: false,
                        cellTemplate: `
                                <div class="GridButton">
                                    <center>
                                        <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.aboutGTSCtrl.downloadItem(row.entity)">
                                            <span class="fa fa-file-pdf-o" style="color: #cb0606; font-size:22px"></span>
                                        </button>
                                    </center>
                                </div>
                                `,
                        enableColumnMenu: false,
                        width: 51,
                    },]
            };

            registerGridApi(gridApi: uiGrid.IGridApi) {
                this.apiManualList = gridApi;
                this.apiManualList.cellNav.on.navigate(this.$scope, (gridSelection) => {
                    this.apiManualList.selection.selectRow(gridSelection.row.entity);
                });

                this.apiManualList.core.on.sortChanged(this.$scope, (grid, sortColumns) => {
                    if (sortColumns[0]) {
                        //this.sortName = sortColumns[0].name;
                        //this.sortDirection = sortColumns[0].sort.direction;
                    }
                    else {
                        //this.sortName = "";
                        //this.sortDirection = "";
                    }
                });
            }

            downloadItem = (row: interfaces.applicationcore.IManualDisplay) => {
                this.apiManualList.selection.clearSelectedRows();

                this.$timeout(() => {
                    this.apiManualList.selection.selectRow(row);
                });

                //Get the manual
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'about.gts'
                },
                    () => {
                        if (row.Id > 0) {
                            this.aboutGTSService.getManual(row.Id).then(() => {
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            });
                        }
                    });
            }
        };

        angular.module("app").controller("aboutGTSCtrl", controllers.applicationcore.aboutGTSCtrl);
    }
}